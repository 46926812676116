export const DEVELOPMENT = 'development';
export const DEMO = 'demo';
export const PRODUCTION = 'production';

export const IS_PRODUCTION = (process.env.CONFIG === PRODUCTION);

export const DATE_FORMAT = 'DD MMMM YYYY';

export const CURRENCY_CODE_TO_SIGN = {
  USD: '$',
  SGD: 'S$',
  CNY: '¥',
  UAH: '₴',
  PHP: '₱',
  RM: 'RM',
  PLN: 'zł',
  CAD: 'C$',
  EUR: '€',
  AUD: 'A$',
  KZT: '₸',
  RUR: '₽',
};

export const MOBILE_BREAKPOINT = 991;
export const TABLET_BREAKPOINT = 991;

export const VOUCHER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const AVAILABLE_PAGINATE_SIZES = [5, 10, 20, 30, 50, 100];
export const DEFAULT_PAGINATE_SIZES = {
  mobile: 50,
  desktop: 50
};
