import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;

class AuthAPI {
  static setToken = (token) => {
    Request.token = token;
  };

  static login = async (credentials) => {
    const {token, refreshToken } = await Request.POST(`/distributor/login`, credentials);

    Request.updateTokens(token, refreshToken);
  };

  static getUserInfo = async () => {
    try {
      const userInfo = await Request.GET(`/distributor/info`);

      return userInfo;
    } catch (error) {
      return error;
    }
  };

  static refreshToken = async () => {
    const {token, refreshToken} = await Request.POST(`/user/refresh-token`, {
      token: Request.authorizationToken,
      refreshToken: Request.refreshToken,
    });

    Request.updateTokens(token, refreshToken);

    return AuthAPI.getUserInfo();
  };

  static logout = () => {
    Request.token = null;
  };
}

export default AuthAPI;
