import { configure } from 'mobx';

import LaunchStore from 'stores/launch';
import UI from './UI';
import AccountStore from './Account';
import AnalyticsStore from './analytics';
import SubscriptionsStore from './subscriptions';
import DistributorStore from './distributor/Distributor';
import MasterStore from './master/Master';
import ChartsStore from './charts';

configure({ enforceActions: 'observed' });

const initStores = () => {
  const Account = new AccountStore();
  const Distributor = new DistributorStore();
  const Analytics = new AnalyticsStore();
  const Master = new MasterStore();
  const Subscriptions = new SubscriptionsStore();
  const Charts = new ChartsStore();
  const Launch = new LaunchStore();

  return {
    Account,
    Distributor,
    Master,
    Analytics,
    Subscriptions,
    Charts,
    Launch,
    ...UI
  };
};

export default initStores;
