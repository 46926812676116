import { createGlobalStyle } from 'styled-components';

import { MOBILE_BREAKPOINT } from 'utils/constants';

import './semantic.min.css';

const licenseBorderRadius = '12px';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
  }

  input {
    border: none;
  }

  ul {
    list-style: none;
  }

  object {
    pointer-events: none;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .Popover {
    z-index: 10000;
  }

  .intl-tel-input {
    width: 100%;
  }

  .logo-link {
    display: flex;
    align-items: center;
    height: 100%;
  }

  button.button.backButton {
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e1e2;
  }

  .backButton > i.icon {
      background-color: #d6d7d8;
  }

  div.tableFilterInput {
    width: 100%;
  }

  .tableFilterInput > input {
      width: 100%
  }

  .statusField {
    font-family: Roboto, sans-serif;
  }

  .inactiveRow {
      color: rgba(0, 0, 0, 0.4);
  }

  .clickableRow {
      cursor: pointer;
  }

  div.listWrapper {
    width: 100%;
    min-height: 50px;
    position: relative;
  }

  form.newAdvancedEditorContainer {
    width: 100%;
    padding: 0 10px;
  }

  section.controls {
    border-bottom: none;
  }

  div.rangeFilterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }

  img.imageLoaderImage {
    width: 276px;
    height: 276px;
  }

  button.button.imageLoaderButton {
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e1e2;
  }

  button.button.imageLoaderButton > i.icon {
    background-color: #d6d7d8;
  }

  div.label {
    margin-bottom: 5px;
  }

  .confirmationEmailContainer {
    top: 300px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      top: 200px;
    }
  }

  .confirmationEmail {
    text-align: center;
  }

  button.button.editPropertyButton {
    display: block;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e1e2;
  }

  button.button.editPropertyButton > i.icon {
    background-color: #d6d7d8;
  }

  .navigationLink {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #3f3f4b;re
    font-weight: bore
    text-decorationre
    transition: bacre;

    &:hover {
      color: inherit;
      background: #ececec;
    }
  }

  .timeInput {
    min-width: 90px;
  }

  .analyticsTableCell {
    height: 35px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: #3f3f4b;

    @media screen and (max-width: 479px) {
      height: 30px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .analyticsTableCell.padded {
    padding-left: 15px;
  }

  .analyticsTableCell.padded.totalAmount {
     padding-left: 32px;
  }

  .analyticsTableCell.padded.colored {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .analyticsHeaderCell {
    font-weight: normal;
  }

  form.createProduct {
    width: 100%;
    margin-bottom: 60px;
  }

  .mobileNavigation {
    background-color: #ffffff;
  }

  div.input-range__track--active {
    background: #2185d0;
  }

  .ui.page.modals.dimmer {
    background-color: rgba(0, 0, 0,.5)
  }

  .ui.modal.licenceIssueModal {
    border-radius: 12px;

    & .header,
    & .content {
      padding: 16px;
      text-align: center;
    };

    & .header {
      border-bottom: 0;
      padding-top: 40px !important;
      border-top-left-radius: ${licenseBorderRadius};
      border-top-right-radius: ${licenseBorderRadius};
    }

    & .content {
      border-bottom-left-radius: ${licenseBorderRadius};
      border-bottom-right-radius: ${licenseBorderRadius};
    }
  }
  .ui.vertical.menu {
    width: auto;
  }
  .Popover-above {
    right: 60px;
    left: auto!important;
  }
  .ui.toggle.checkbox input:checked~.box:before,
  .ui.toggle.checkbox input:checked~label:before {
    background-color: #518299 !important;
  }

  .ui.toggle.checkbox .box:before,.ui.toggle.checkbox label:before,
  .ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
    z-index: 0 !important;
  }

  .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
    background-color: #1d79be;
  }
  
  .datepicerWrapper .field {
    width: 100% !important;
  }
  
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .clndr-calendars-wrapper {
      grid-template-columns: repeat(auto-fill, 240px) !important;
    }
  }

`;

export default GlobalStyle;
