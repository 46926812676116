import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Spinner } from 'components/shared';
import { Icon, Pagination, Segment } from 'semantic-ui-react';

import useWindowWidthHook from 'utils/hooks/useWindowWidth';
import { DEFAULT_PAGINATE_SIZES } from 'utils/constants';

import PaginationSize from './pagination-size';

import {
  Wrapper,
  Placeholder,
  Table,
  HeaderCell,
  TableRow,
  TableData,
  PaginateWrapper,
  PaginationSizeWrapper
} from './styles';

export const DataWrapper = ({
  children,
  headers = [],
  componentForMobileView,
  data = [],
  loading,
  bordered,
  selectable,
  keyProperty,
  sizeable,
  showPagination,
  paginateOptions,
  mobileBreakpoint,
  handlerSort,
  sorted,
}) => {
  const width = useWindowWidthHook();
  const [currentSize, setSize] = useState(DEFAULT_PAGINATE_SIZES.desktop);
  const { totalPages, totalElements, onPageChange } = paginateOptions;

  const handleChangePage = (event, { activePage = 0 }) => {
    onPageChange(activePage - 1, currentSize);
  };

  const fetchTableData = size => {
    const { onPageChange } = paginateOptions;
    onPageChange(0, size);
    setSize(size);
  };

  if (data && data.length === 0 && !loading) {
    return (
      <Placeholder>
        <Segment>
          <FormattedMessage
            id='table.placeholder'
            defaultMessage='No Data Available'
          />
        </Segment>
      </Placeholder>
    );
  }

  if (componentForMobileView && (width < mobileBreakpoint)) {
    const Component = componentForMobileView;
    return data.map((item, index) => {
      return (
        <Fragment key={item[keyProperty] || index}>
          <Component
            headers={headers}
            data={item}
          />
          <Spinner active={loading} />
        </Fragment>
      );
    });
  }

  const getRowVariants = (variants = []) => variants.join(' ');


  const renderHeader = (headers) => {
    return headers.map(({ id, property, name, className = '', variant, sortable, width }) => {
      const [p, d] = (sorted || '').split('|');
      let direction = null;

      if (p === property && sortable) {
        if (d === 'DESC') {
          direction = 'ASC';
        } else {
          direction = 'DESC';
        }
      }

      const iconStyles = { position: 'absolute', right: -4, margin: 0, top: 'calc(50% - 7px)', lineHeight: 1 };

      return (
        <HeaderCell
          onClick={sortable ? () => handlerSort(property, direction || 'DESC') : null}
          style={width ? {width: width} : {}}
          key={id || property}
          className={`${getRowVariants(variant)} ${className}`}
        >
          {name}
          {direction && (
            <Icon style={iconStyles} name={direction === 'DESC' ? "sort amount down" : "sort amount up"} />
          )}
          {sortable && !direction && (
            <Icon style={iconStyles} name="sort" />
          )}
        </HeaderCell>
      );
    });
  };
  const renderBody = (headers, data) => {
    return Array.isArray(data) && data.map((item, index) => {
      return (
        <TableRow
          className={item.rowClassName || ''}
          key={item[keyProperty]}
        >
          {
            headers.map(({ property, variant, ignoreTd = false }) => {
              return (
                <Fragment key={item[keyProperty] + property || `${property}_${index}`}>
                  {
                    ignoreTd
                      ? item[property]
                      : (
                        <TableData
                          onClick={item.onClickPhoneNumber && property === item.onClickPhoneNumber ? ()=>{} : item.onRowClick}
                          selectable={selectable}
                          className={`${getRowVariants(variant)}`}
                        >
                          {item[property]}
                        </TableData>
                      )

                  }
                </Fragment>
              )
            })
          }
        </TableRow>
      )
    });
  };

  return (
    <Wrapper>
      <Table bordered={bordered}>
        <thead>
          <tr>
            { renderHeader(headers) }
          </tr>
        </thead>
        <tbody>
          { renderBody(headers, data) }
        </tbody>
      </Table>
      {
        sizeable && (
        <PaginationSizeWrapper>
          <PaginationSize
            currentSize={currentSize}
            changePaginationSize={fetchTableData}
          />
        </PaginationSizeWrapper>
        )
      }

      {
        ( showPagination && totalElements >= currentSize)  && (
          <PaginateWrapper>
            <Pagination
              boundaryRange={0}
              defaultActivePage={1}
              ellipsisItem={null}
              siblingRange={1}
              onPageChange={handleChangePage}
              totalPages={totalPages}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
            />
          </PaginateWrapper>
        )
      }

      {children}

      <Spinner active={loading} />
    </Wrapper>
  );
};

DataWrapper.defaultProps = {
  children: null,
  bordered: true,
  sizeable: true,
  keyProperty: 'id',
  mobileBreakpoint: '',
  showPagination: true,
  selectable: false,
  componentForMobileView: null,
  paginateOptions: {
    last: false,
    size: 10,
    totalPages: 10,
    totalElements:10,
    onPageChange: () => {}
  },
  handlerSort: () => {},
};

DataWrapper.propTypes = {
  keyProperty: PropTypes.string,
  bordered: PropTypes.bool,
  mobileBreakpoint: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    property: PropTypes.string.isRequired,
    variant: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
  })).isRequired,
  sizeable: PropTypes.bool,
  selectable: PropTypes.bool,
  showPagination: PropTypes.bool,
  paginateOptions: PropTypes.shape({
    last: PropTypes.bool,
    size: PropTypes.number,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    onPageChange: PropTypes.func
  }),
  componentForMobileView: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  handlerSort: PropTypes.func,
  sorted: PropTypes.string,
};
