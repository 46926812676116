import React, { lazy } from 'react';
import { inject, observer } from 'mobx-react';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { Redirect } from 'react-router-dom';

import { routes } from 'routing';

const Dashboard = lazy(() => import('components/dashboard'));

const DashboardScene = inject('Account')(observer(({ Account }) => {
  if (!Account.isAuthorized) {
    return <Redirect to={routes.auth} />;
  } else if (Account.currentDistributor.distributorRole === 'MASTER_ACCOUNT') {
    return (
      <Redirect to={routes.master} />
    )
  }

  return <Dashboard />;
}));

export default hot(DashboardScene);
