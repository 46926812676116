import styled from 'styled-components';

export const Mark = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #1aa6b7;
`;
