import React from 'react';
import PropTypes from 'prop-types';

import { Dimmer, Loader } from 'semantic-ui-react';

export const Spinner = ({ active }) => (
  <Dimmer 
    active={active}
    inverted
  >
    <Loader inverted />
  </Dimmer>
);

Spinner.propTypes = {
  active: PropTypes.bool.isRequired,
};
