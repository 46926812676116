import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { IntlProvider } from "react-intl";

import GlobalStyle from './styles';
import App from './App';

const renderApp = (stores, history, i18n) => {
  ReactDOM.render(
    <Provider {...stores}>
      <IntlProvider 
        locale={i18n.language} 
        messages={i18n.messages}
      >
        <Router history={history}>
          <Fragment>
            <GlobalStyle />
            <App />
          </Fragment>
        </Router>
      </IntlProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

export default renderApp;
