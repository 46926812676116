import styled from 'styled-components';

const mobileViewport = '479px';
const tableHoverColor = 'rgba(0, 0, 0, .05)';

export const Wrapper = styled.div`
  position: relative;
  min-height: 250px;
  overflow: auto;

`;

export const Placeholder = styled.div`
  text-align: center;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  cursor: auto;
  text-align: inherit;
  padding: .9em .7em;
  vertical-align: inherit;
  font-weight: 700;
  text-transform: none;
  border: 0;
  border-radius: .28571429rem .28571429rem 0 0;
  position: relative;
  color: rgba(0, 0, 0, .87);
  transition: box-shadow 300ms cubic-bezier(.4, 0, .2, 1) 0ms;
  background-color: #fff;
  border: none;

  thead th {
    border-left: none;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    background: #fff;
    line-height: 1.4;
    vertical-align: inherit;
    border-bottom: 0;
    background-color: #eff0f1;
  }

  td {
    padding: 8px 20px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    line-height: 1.4;
    vertical-align: inherit;
    border-top: 1px solid rgba(224,224,224,1);

    @media screen and (max-width: ${mobileViewport}) {
      padding: 8 16px;
    }
  }
`;

export const TableRow = styled.tr`
  border-top: 1px solid #ebebeb;
  color: #3f3f4b;
  font-family: Roboto, san-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  height: 35px;
  letter-spacing: normal;
  line-height: 2.14;
  background-color: rgba(236, 236, 236, 0.5);

  &:nth-child(2n) {
    background-color: #eff0f1;
  }

  &:hover {
    background: #fcfcfc !important;
  }

  &.disabled {
    opacity: .4;
    pointer-events: none;
    touch-action: none;
  }

  & > td {
    &.longContent {
      max-width: 300px;
    }
  }

  @media screen and (max-width: ${mobileViewport}) {
    font-size: 12px;
    height: 30px;
    line-height: 1;

    & > td {
      max-width: 180px;

      &.nowrap {
        white-space: pre-wrap;
      }
    }
  }
`;

export const HeaderCell = styled.th`
  position: relative;
  padding: 12px 20px;
  cursor: pointer;
  text-align: left;

  @media screen and (max-width: ${mobileViewport}) {
    text-align: center;
    padding: 8px;
  }

  &.left {
    text-align: left;
  }

  &.small {
    width: 40px;
  }
`;

export const TableData = styled.td`
  padding: 5px;
  cursor: ${({ selectable }) => selectable ? 'pointer' : 'pointer'};
`;

export const PaginateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const PaginationSizeWrapper = styled.div`
  & > div {
    margin-top: 0;
  }
`;
