import { extractFiltersQuery } from 'api/utils/helpers';
import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/distributor/stats';

class ChartsAPI {
  static getData = props => {
    return Request.GET(`${ENDPOINT}?${extractFiltersQuery(props)}`);
  };
}

export default ChartsAPI;
