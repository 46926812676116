import config from 'config';

import { PRODUCTION, DEMO } from 'utils/constants';

const APP_ID = config.intercom;
const INTERCOM_ENABLED = false;
    //(process.env.CONFIG === PRODUCTION || process.env.CONFIG === DEMO);

const loadIntercom = function() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + APP_ID;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

class IntercomManager {
  static setup = () => {
    loadIntercom();

    IntercomManager.boot();
  };

  static boot = () => {
    if (INTERCOM_ENABLED) {
      window.Intercom('boot', {
        app_id: APP_ID,
      });
    }
  };

  static loginUser = (email) => {
    if (INTERCOM_ENABLED) {
      window.Intercom('boot', {
        app_id: APP_ID,
        email,
        acquisition: process.env.CONFIG,
      });
    }
  };

  static shutDown = () => {
    window.Intercom('shutdown');

    IntercomManager.boot();
  }
}

export default IntercomManager;

