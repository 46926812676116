import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { inject, observer } from 'mobx-react';

import { routes } from 'routing';

const Authentification = lazy(() => import('components/authentification'));

const AuthenticationScene = inject('Account')(observer(({ Account }) => {
  if (!Account.isAuthorized) {
    return <Authentification />;
  } else if (Account.currentDistributor.distributorRole === 'MASTER_ACCOUNT') {
    return (
      <Redirect to={routes.master} />
    )
  }

  return <Redirect to={routes.dashboard} />
}));

export default hot(AuthenticationScene);
