import 'core-js/stable';
import 'regenerator-runtime/runtime';

import initStores from 'stores';
import setupI18n from 'i18n';
import history from 'routing';
import Intercom from 'thirdparty/Intercom';
import renderApp from 'render';

const initApp = async (stores) => {
  try {
    await stores.Account.refreshUserInfo();
  } catch (error) {
    return;
  }
};

const startApp = async () => {
  try {
    const stores = initStores();
    const i18n = setupI18n();

    Intercom.setup();

    await initApp(stores);

    return renderApp(stores, history, i18n);
  } catch (error) {
    console.error(error);
  }
};

startApp();
