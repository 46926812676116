import queryString from 'query-string';
import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/distributor/masterAccount';

class MasterAPI {
  static getMasterList  = ({ page, size }) => {
    return Request.GET(`${ENDPOINT}?${queryString.stringify({ page, size })}`);
  };

  static getMasterKPIList = ({ page, size, startDate = 0, endDate = new Date().getTime(), currency: currencies, phoneNumber } = {}) => {
    return Request.GET(`${ENDPOINT}/kpi?${queryString.stringify({ currencies, startDate, endDate, page, size, phoneNumber })}`);
  };
}

export default MasterAPI;
