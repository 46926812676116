import { observable, action } from 'mobx';

class Modal {
  @observable open = false;
  @observable data = {};

  @action
  hide = () => {
    this.open = false;
  };

  @action
  openModal = data => {
    this.open = true;
    this.data = data;
  };

  @action
  toggle = () => {
    this.open = !this.open;
  }
}

export default Modal;
