import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import uk from 'react-intl/locale-data/uk';
import id from 'react-intl/locale-data/id';
import ru from 'react-intl/locale-data/ru';
import { getUserLocale } from 'get-user-locale';
import ENMessages from './translations/en.json';
import UKMessages from './translations/uk.json';
import IDMessages from './translations/id.json';
import RUMessages from './translations/ru.json';
import { polyfillI18n } from './utils';

const messages = {
  en: ENMessages,
  uk: UKMessages,
  id: IDMessages,
  ru: RUMessages,
};

export const getI18nData = () => {
  const locale = getUserLocale();
  const language = locale.split(/[_-]+/)[0];

  return {
    language: messages[language] ? language : 'en',
    messages: messages[language] || messages.en,
  };
};

export const getStringInLocale = (key, defaultValue) => {
  return getI18nData().messages[key] || defaultValue;
};

const setupI18n = () => {
  polyfillI18n();

  addLocaleData([...en, ...uk, ...id, ...ru]);

  return getI18nData();
};

export default setupI18n;
