import { createBrowserHistory } from 'history';

export const routes = {
  charts: '/charts',
  analytics: '/analytics',
  dashboard: '/',
  auth: '/auth',
  connectedGroups: '/connected-groups',
  login: '/auth/login',
  merchants: '/merchants',
  merchantGroup: '/merchants/:id/:currency',
  master: '/master',
  masterRestaurants: '/master/restaurants',
};

const history = createBrowserHistory();

export default history;
