import { action, computed, observable, runInAction } from 'mobx';
import ChartsAPI from 'api/charts';
import MerchantAPI from 'api/merchant';
import { getLocalizedString } from 'i18n/utils';
import { formatDate } from 'utils/format-date';

const addAllValueToOptions = arr => [
  { text: getLocalizedString('global.all'), value: 'all', key: 'all' },
  ...arr,
];

class ChartsStore {
  @observable refsList = [];
  @observable distributorTags = [];
  @observable charts = [];
  @observable loading = false;
  @observable date = [new Date(), new Date()];

  @action
  fetchData = async params => {
    const amountInvoicesBilled =
      getLocalizedString('charts.table.amountInvoicesBilled') || 'Amount invoices billed';
    const totalGmvDo = getLocalizedString('charts.table.totalGmvDo') || 'Total GMV DO';
    const totalGmvPos = getLocalizedString('charts.table.totalGmvPos') || 'Total GMV POS';

    this.loading = true;
    const { items } = await ChartsAPI.getData({ ...params });
    items.shift();
    const newData = items.map(item => {
      const date = formatDate(item.date, 'MMMM YYYY');
      return {
        ...item,
        date,
        [amountInvoicesBilled]: item.amountInvoicesBilled,
        [totalGmvDo]: item.totalGmvDo,
        [totalGmvPos]: item.totalGmvPos,
      };
    });

    runInAction(() => {
      this.charts = newData;
      this.loading = false;
    });
  };

  @action
  changeDate = value => {
    runInAction(() => {
      this.date = value;
    });
  };

  @action
  fetchRefs = async () => {
    const refs = await MerchantAPI.getRefs();

    runInAction(() => {
      this.refsList = addAllValueToOptions(
        refs.refList
          .filter(i => i)
          .map(i => ({
            key: i,
            value: i,
            text: i,
          })),
      );
    });
  };

  @action
  fetchDistributorTags = async () => {
    const distributorTags = await MerchantAPI.getDistributorTags();

    runInAction(() => {
      this.distributorTags = distributorTags.items;
    });
  };
}

export default ChartsStore;
