import React, { Fragment } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { Message, Transition, Icon } from 'semantic-ui-react';

import { Container } from './styles';

const Notification = inject('Notification')(observer(({ Notification }) => {
 const { text, isError } = Notification.message;

 const hideActiveNotification = () => {
   Notification.hide();
 }

 return (
   <Container onClick={hideActiveNotification}>
     <Transition
       visible={Notification.visible}
       animation='fade down'
       duration={400}
     >
       <Message
         positive={!isError}
         negative={isError}
       >
         <Icon name="close" onClick={hideActiveNotification} />
         {
           (text === 'Success') ? (
              <Fragment>
                <FormattedMessage id='notifications.success' defaultMessage='Success' />
              </Fragment>
            ) : text
         }
       </Message>
     </Transition>
   </Container>
 );
}));

Notification.wrappedComponent.propTypes = {
 Notification: MobXPropTypes.observableObject.isRequired,
};

export default Notification;
