import queryString from 'query-string';
import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/distributor';

class MerchantAPI {
  static getMerchantKPIList = ({ page, size, startDate = 0, endDate = new Date().getTime(), currency: currencies, phoneNumber, sortingDirection, sortingFieldName, tags } = {}) => {
    return Request.GET(`${ENDPOINT}/group/kpi?${queryString.stringify({ currencies, startDate, endDate, page, size, phoneNumber, sortingDirection, sortingFieldName, tags })}`);
  };

  static getMerchantGroupKPIList = (group, { startDate = 0, endDate = new Date().getTime() } = {}) => {
    return Request.GET(`${ENDPOINT}/group/${group}/kpi?startDate=${startDate}&endDate=${endDate}`);
  };

  static getMerchantGroupList = ({ page, size, phoneNumber = '', email = '' }) => {
    return Request.GET(`${ENDPOINT}/merchant-group/list?page=${page}&size=${size}&phoneNumber=${phoneNumber}&email=${email}`);
  };

  static getMerchantInvoicesList = (group, { page, size }) => {
    return Request.GET(`${ENDPOINT}/invoice/${group}?page=${page}&size=${size}`);
  };

  static createInvoice = (params) => {
    return Request.POST(`${ENDPOINT}/invoice`, params);
  };

  static deleteInvoice = (id) => {
    return Request.DELETE(`${ENDPOINT}/invoice/${id}`);
  };

  static assignRefs = (merchantGroupId, ref) => Request.PUT(`${ENDPOINT}/assignRef/${merchantGroupId}`, { refList: ref })

  static getCurrencies = () => Request.GET(`/merchant/currency`);

  static getDistributorTags = () => Request.GET('/merchant/distributorTag');

  static changeDistributorTag = params => Request.PUT(`/distributor/group/distributorTag`, params);

  static getRefs = () => Request.GET(`${ENDPOINT}/refs`);

  static blockMerchant = params => Request.PUT(`${ENDPOINT}/group/blockAdmin`, params);

  static unblockMerchant = params => Request.PUT(`${ENDPOINT}/group/unblockAdmin`, params);

  static addRemark = (merchantGroupId, comment) => Request.PUT(`${ENDPOINT}/group/distributorComment`, {
    comment,
    merchantGroupId,
  });

  static addGroupProdDate = (data) => Request.PUT(`${ENDPOINT}/group/prodDate`, data);

  static addMerchantProdDate = (data) => Request.PUT(`${ENDPOINT}/merchant/prodDate`, data);

  static getPaymentAccounts = () => Request.GET(`${ENDPOINT}/invoice/paymentAccounts`);

  static setPaidInvoice = (data) => Request.PUT(`${ENDPOINT}/invoice/mark-paid`, data);

  static setAppUrl = (data) => Request.PUT(`${ENDPOINT}/merchant/app-url`, data);

  static getLaunchData = (merchantGroupId) => Request.GET(`${ENDPOINT}/launch-configuration/${merchantGroupId}`);

  static putLaunchData = (merchantGroupId, data) => Request.PUT(`${ENDPOINT}/launch-configuration/${merchantGroupId}/check-list`, data);
}

export default MerchantAPI;
