import config from 'config';
import { getAcceptLanguageHeader } from 'i18n/utils';

const SERVER_URL = config.serverURL;
const APIMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
const SUCCESS_CODE = 'SUCCESS';
export const UNAUTHORIZED = 'UNAUTHORIZED';

let instance = null;

export default class APIConnector {
  constructor() {
    if (instance) {
      throw new Error('Cannot create APIConnector instance');
    }

    this.authorizationToken = localStorage.getItem('authToken') || null;
  }

  static get instance() {
    if (!instance) {
      instance = new APIConnector();
    }

    return instance;
  }

  updateTokens = (token, refreshToken) => {
    this.token = token;
    this.refreshToken = refreshToken;
  };

  set token(token) {
    if (token) {
      localStorage.setItem('authToken', token);

      this.authorizationToken = token;
    } else {
     localStorage.removeItem('authToken');

     this.authorizationToken = null;
    }
  }

  set refreshToken(token) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken() {
    return localStorage.getItem('refreshToken') || null;
  }

  prepareRequest = (url, method, data) => {
    const requestOptions = {
      method: method,
      headers: new Headers({
        'Accept': 'application/json',
        'Accept-Language': getAcceptLanguageHeader(),
        'Content-Type': 'application/json',
        'token': this.authorizationToken,
      }),
    };

    if (method !== APIMethods.GET) {
      requestOptions.body = JSON.stringify(data);
    }

    return new Request(SERVER_URL + url, requestOptions);
  };

  makeRequest = async (request) => {
    try {
      const response = await fetch(request);
      const body = await response.json();

      return body.response;
    } catch (error) {
      throw new Error('503 Server unavailable');
    }
  };

  sendRequest = async (request) => {
    const {code, errors, data} = await this.makeRequest(request);

    if (code === UNAUTHORIZED) {
      throw new Error(UNAUTHORIZED);
    }

    if (code !== SUCCESS_CODE) {
      errors.forEach(error => {
        throw new Error(error.message);
      });
    } else {
      return data;
    }
  };

  GET = (url) => this.sendRequest(this.prepareRequest(url, APIMethods.GET));

  POST = (url, data) => this.sendRequest(this.prepareRequest(url, APIMethods.POST, data));

  PUT = (url, data) => this.sendRequest(this.prepareRequest(url, APIMethods.PUT, data));

  DELETE = (url, data) => this.sendRequest(this.prepareRequest(url, APIMethods.DELETE, data));

  POST_FILE = (url, data) => {
    const requestOptions = {
      method: APIMethods.POST,
      body: data,
      headers: new Headers({
        token: this.authorizationToken,
      })
    };
    const request = new Request(SERVER_URL + url, requestOptions);

    return this.sendRequest(request);
  };
}
