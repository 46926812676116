import queryString from 'query-string';
import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/distributor/merchant-group-subscription';

class SubscriptionsAPI {
  static getSubscriptions = (merchantsGroupId) => {
    return Request.GET(`${ENDPOINT}/${merchantsGroupId}`);
  };

  static saveSubscriptions = (obj) => {
    return Request.PUT(ENDPOINT, obj);
  };
}

export default SubscriptionsAPI;
