import React, { Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { routes } from 'routing';
import Notification from 'components/shared/notification';
import MomentUtils from '@date-io/moment';
import { Spinner } from 'components/shared';

import Auth from 'scenes/Authentification';
import Dashboard from 'scenes/Dashboard';
import MasterDashboard from 'scenes/MasterDashboard';

const App = withRouter(() => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Suspense fallback={<Spinner active />}>
      <Switch>
        <Route
          exact
          path={routes.auth}
          component={Auth}
        />
        <Route
          path={routes.master}
          component={MasterDashboard}
        />
        <Route
          path={routes.dashboard}
          component={Dashboard}
        />
      </Switch>
      <Notification />
    </Suspense>
  </MuiPickersUtilsProvider>
));

export default App;
