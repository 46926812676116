import { action, computed, observable, runInAction } from 'mobx';
import AnalyticsAPI from 'api/analytics';
import MerchantAPI from 'api/merchant';
import { getLocalizedString } from 'i18n/utils';

const addAllValueToOptions = (arr) => [{text: getLocalizedString('global.all'), value: 'all', key: 'all' }, ...arr]

class AnalyticsStore {
  @observable currencies = [];
  @observable refsList = [];
  @observable paymentAccountsOptions = [];
  @observable analytics = { items: [] };
  @observable loading = false;
  @observable date = [new Date(), new Date()];

  @action
  changeDate = (value) => {
    runInAction(() => {
      this.date = value;
    })
  }

  @computed get data() {
    return this.list.map(data => ({ ...data }));
  }

  @action
  fetchRefs = async () => {
    const refs = await MerchantAPI.getRefs();

    runInAction(() => {
      this.refsList = addAllValueToOptions(refs.refList.filter(i => i).map(i => ({
        key: i,
        value: i,
        text: i,
      })));
    });
  };

  @action
  fetchCurrencies = async () => {
    const currencies = await MerchantAPI.getCurrencies();

    runInAction(() => {
      this.currencies = currencies.items;
    });
  };

  @action
  getPaymentAccounts = async () => {
    const { items } = await MerchantAPI.getPaymentAccounts();

    runInAction(() => {
      this.paymentAccountsOptions = addAllValueToOptions(items.map(i => ({
        key: i.id,
        value: String(i.id),
        text: i.name,
      })));
    });
  };

  @action
  fetchData = async (obj) => {
    this.loading = true;
    const data = await AnalyticsAPI.getList(obj);

    runInAction(() => {
      this.analytics = data;
      this.loading = false;
    });
  };
}

export default AnalyticsStore;
