import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import styles from './styles.css';

export const Text = props => {
  const {
    tag: Tag,
    className,
    color,
    intl,
    variant,
    ...rest
  } = props;

  const classnames = classNames(
    `base ${Tag} ${color} ${variant}`,
    styles,
    className
  );

  return (
    intl ? (
      <FormattedMessage id={intl}>
        {
          txt => (
            <Tag className={classnames} {...rest}>
              {txt}
            </Tag>
          )
        }
      </FormattedMessage>
    )
      : <Tag className={classnames} {...rest} />
  );
};

Text.defaultProps = {
  tag: 'p',
  className: '',
  color: '',
  intl: '',
  variant: ''
};

Text.propTypes = {
  tag: PropTypes.oneOf(['div', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'b']),
  className: PropTypes.string,
  color: PropTypes.string,
  intl: PropTypes.string,
  variant: PropTypes.string,
};
