import { observable, action, runInAction, computed } from 'mobx';

import AuthAPI from 'api/auth';
import history, { routes } from 'routing';
import Intercom from 'thirdparty/Intercom';

import UIStore from 'stores/UI';

class Account {
  @observable currentDistributor = {};

  constructor (Notification = UIStore.Notification) {
    this.Notification = Notification;
  }

  @computed get isAuthorized() {
    const { email } = this.currentDistributor;
    return !!email;
  }

  @computed get isRoleRegular() {
    const { distributorRole } = this.currentDistributor;
    return distributorRole === 'REGULAR';
  }

  @computed get isMalaysiaManager() {
    const { distributorRole } = this.currentDistributor;
    return distributorRole === 'MALAYSIA_MANAGER';
  }

  @computed get isUkrainianManager() {
    const { distributorRole } = this.currentDistributor;
    return distributorRole === 'UKRAINIAN_MANAGER';
  }

  @computed get isORTYManager() {
      const { distributorRole } = this.currentDistributor;
      return distributorRole === 'ORTY_MANAGER';
  }

  @computed get isManager() {
    const { distributorRole } = this.currentDistributor;
    return distributorRole === 'ORTY_MANAGER' || distributorRole === 'MALAYSIA_MANAGER' || distributorRole === 'UKRAINIAN_MANAGER';
  }

  @action
  authorizeUser = async () => {
    const distributorInfo = await AuthAPI.getUserInfo();

    runInAction(() => {
      this.currentDistributor = distributorInfo;

      Intercom.loginUser(distributorInfo.email);
    });
  };

  @action
  refreshUserInfo = async () => {
      const distributorInfo = await AuthAPI.getUserInfo();

      runInAction(() => {
          this.currentDistributor = distributorInfo;
      });
  };

  login = async (credentials) => {
    await AuthAPI.login(credentials).catch(error => {
      this.Notification.showError(error.message);
    });
    await this.authorizeUser();

    history.push(routes.dashboard);
  };

  @action
  autoLogin = async (token) => {
    AuthAPI.setToken(token);
    await this.authorizeUser();
    history.push(routes.dashboard);
  };

  logout = () => {
    AuthAPI.logout();

    runInAction(() => {
      this.currentDistributor = {};
    });

    history.replace(routes.login);
  };

  @action
  updateInfo = (key, value) => {
    this.currentDistributor[key] = value;
  };

  updateAccount = () => UsersAPI.updateUser(this.currentDistributor);
}

export default Account;
