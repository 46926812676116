import {DEVELOPMENT, PRODUCTION, DEMO} from 'utils/constants';

const LOCAL_CONFIG = {
  displayDevTools: false,
  serverURL: 'https://admin-api.local-weebo.com/admin-api',
  intercom: 'yhxl0nl5',
};

const DEMO_CONFIG = {
    displayDevTools: false,
    serverURL: 'http://35.194.238.104:8092/admin-api',
    intercom: 'yhxl0nl5',
};

const DEV_CONFIG = {
  displayDevTools: false,
  serverURL: 'https://admin-api.local-weebo.com/admin-api',
  intercom: 'yhxl0nl5',
};

const PROD_CONFIG = {
  displayDevTools: false,
  serverURL: `https://admin-api.${window.DOMAIN}/admin-api`,
  intercom: 'yhxl0nl5',
};

let config; //eslint-disable-line

switch (process.env.CONFIG) {
  case DEVELOPMENT:
    config = DEV_CONFIG;
    break;
  case PRODUCTION:
    config = PROD_CONFIG;
    break;
  case DEMO:
    config = DEMO_CONFIG;
    break;
  default:
    config = LOCAL_CONFIG;
}

export default config;
