import { action, computed, observable, runInAction } from 'mobx';
import MerchantAPI from 'api/merchant';

class DistributorStore {
  @observable currencies = [];
  @observable refs = [];
  @observable merchants = { items: [] };
  @observable merchants2 = { items: [] };
  @observable merchantGroup = { items: [] };
  @observable merchantInvoices = { items: [] };
  @observable merchantsLoading = false;
  @observable isLicenseLoading = false;
  @observable merchantGroupLoading = false;
  @observable merchantInvoicesLoading = false;
  @observable distributorTags = [];
  @observable paymentAccountsOptions = [];
  @observable paymentMethodsOptions = [];
  @observable date = [new Date(), new Date()];

  @action
  changeDate = (value) => {
    return this.date = value;
  }

  @computed get data() {
    return this.merchants.map(data => ({ ...data }));
  }

  @action
  setPaidInvoice = async (item) => {
    const paidData = {
      invoiceId: item.id,
      paymentAmount: item.amount,
      paymentDate: item.paidDate,
      transactionDescription: item.description,
    }
    try {
      const responce = await MerchantAPI.setPaidInvoice(paidData);
      if(responce){
        runInAction(() => {
          this.merchantInvoices.items = this.merchantInvoices.items.map(i => {
            if(i.id === item.id){
              return {...responce}
            }
            return i
          });
        });
      }
    } catch (error) {
      throw new Error(error.message)
    }
  };

  @action
  fetchDistributorTags = async () => {
    const distributorTags = await MerchantAPI.getDistributorTags();

    runInAction(() => {
      this.distributorTags = distributorTags.items;
    });
  };

  @action
  changeMerchantsItems = (index, key, value) => {
    runInAction(() => {
      const newMerchantsItems = [...this.merchants.items]
      newMerchantsItems[index][key] = value;
      this.merchants.items = [...newMerchantsItems];
    });
  };

  @action
  changeMerchantItems = (index, key, value) => {
    runInAction(() => {
      const newMerchantsItems = [...this.merchantGroup.items]
      newMerchantsItems[index][key] = value;
      this.merchantGroup.items = [...newMerchantsItems];
    });
  };

  @action
  changeDistributorTag = async (id, tag) => {
    await MerchantAPI.changeDistributorTag({ merchantGroupId: id, tag });
    const merchantsList = [...this.merchants.items];
    const index = merchantsList.findIndex(m => m.merchantGroupId === id);

    if (index >= 0) {
      merchantsList[index] = {
        ...merchantsList[index],
        distributorTag: tag,
      };
    }

    runInAction(() => {
      this.merchants = {
        ...this.merchants,
        items: merchantsList,
      };
    });
  };

  @action
  fetchRefs = async () => {
    const refs = await MerchantAPI.getRefs();

    runInAction(() => {
      this.refs = refs.refList;
    });
  };

  @action
  assignRefs = async (id, refs) => {
    await MerchantAPI.assignRefs(id, refs);

    runInAction(() => {

    });
  };

  @action
  fetchCurrencies = async () => {
    const currencies = await MerchantAPI.getCurrencies();

    runInAction(() => {
      this.currencies = currencies.items;
    });
  };

  @action
  getPaymentAccounts = async () => {
    const { items } = await MerchantAPI.getPaymentAccounts();

    const getPaymentMethodsOptions = (data) => {
      let uniqPaymentMethods = [];
      data.map(item => {
        if(!uniqPaymentMethods.some(i => i === item.paymentMethod)){
          uniqPaymentMethods.push(item.paymentMethod)
        }
      });
      return uniqPaymentMethods.map(i => ({key: i, value: i, text: i }));
    }

    runInAction(() => {
      this.paymentAccountsOptions = items.map(i => ({
        key: i.id,
        value: i.id,
        text: i.name,
        paymentMethod: i.paymentMethod,
      }));
      this.paymentMethodsOptions = getPaymentMethodsOptions(items)
    });
  };

  @action
  changeBlockMerchant = async (id, blockedWebAdmin) => {
    if( blockedWebAdmin) {
      await MerchantAPI.unblockMerchant({id})
    } else {
      await MerchantAPI.blockMerchant({id})
    }
    runInAction(() => {
      this.merchants.items = this.merchants.items.map(i => {
        if(i.merchantGroupId === id){
          return {...i, blockedWebAdmin: !blockedWebAdmin}
        }
        return i
      });
    });
  };


  @action
  fetchData = async (page, size, params) => {
    this.merchantsLoading = true;
    const merchants = await MerchantAPI.getMerchantKPIList({ page, size, ...params });

    runInAction(() => {
      this.merchants = merchants;
      this.merchantsLoading = false;
    });
  };

  fetchMerchants2 = async (page, size, params) => {
    this.merchants2Loading = true;
    const merchants = await MerchantAPI.getMerchantGroupList({ page, size, ...params });

    runInAction(() => {
      this.merchants2 = merchants;
      this.merchants2Loading = false;
    });
  };

  @action
  fetchMerchantGroupListById = async (merchantId, startDate, endDate) => {
    this.merchantGroupLoading = true;

    const response = await MerchantAPI.getMerchantGroupKPIList(merchantId, {startDate, endDate});

    runInAction(() => {
      this.merchantGroup = response;
      this.merchantGroupLoading = false;
    });
  };

  @action
  fetchMerchantInvoicesById = async (merchantId, page, size) => {
    this.merchantInvocesLoading = true;

    const response = await MerchantAPI.getMerchantInvoicesList(merchantId, { page, size });

    runInAction(() => {
      this.merchantInvoices = response;
      this.merchantInvoicesLoading = false;
    });
  };
}

export default DistributorStore;
