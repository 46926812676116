import styled from 'styled-components';

export const Input = styled.div`
  cursor: pointer;
  text-align: right;
  padding-left: 8px;
  padding-right: 24px;
  text-align-last: right;
`;

export const PaginateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: -14px;
  background: #fff;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 99999;
  border-top: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
`;
