import { action, observable, runInAction } from 'mobx';
import SubscriptionsAPI from 'api/subscriptions';

const subscriptionsRestaurantManagementArray = [
  'customerDisplaySystem',
  'deliveryRiderApp',
  'kitchenDisplaySystem',
  'posBusiness',
  'posIndependent',
  'selfServiceKiosk',
];

const subscriptionsMarketingArray = [
  'less30OrderMonthShop',
  'less100OrderMonthShop',
  'less200OrderMonthShop',
  'unlimitedOrderMonthShop',
  'promotions',
  'giftCards',
];

const getTotalRestaurantManagementAndMarketing = subscriptions => {
  const data = [subscriptionsRestaurantManagementArray, subscriptionsMarketingArray].map(array => {
    return array.reduce(
      (acc, item) =>
        (acc += Number(subscriptions[`${item}`]) * Number(subscriptions[`${item}Cost`]) || 0),
      0,
    );
  });
  return { totalRestaurantManagement: data[0], totalMarketing: data[1] };
};

const initSubscriptions = {
  customerDisplaySystem: 0,
  customerDisplaySystemCost: 15,
  customerDisplaySystemPrice: 0,
  deliveryRiderApp: 0,
  deliveryRiderAppCost: 15,
  deliveryRiderAppPrice: 0,
  giftCards: 0,
  giftCardsCost: 50,
  giftCardsPrice: 0,
  id: 0,
  kitchenDisplaySystem: 0,
  kitchenDisplaySystemCost: 15,
  kitchenDisplaySystemPrice: 0,
  less30OrderMonthShop: 0,
  less30OrderMonthShopCost: 10,
  less30OrderMonthShopPrice: 0,
  less100OrderMonthShop: 0,
  less100OrderMonthShopCost: 0,
  less100OrderMonthShopPrice: 0,
  less200OrderMonthShop: 0,
  less200OrderMonthShopCost: 30,
  less200OrderMonthShopPrice: 0,
  unlimitedOrderMonthShop: 0,
  unlimitedOrderMonthShopCost: 0,
  unlimitedOrderMonthShopPrice: 0,
  merchantGroupId: 0,
  posBusiness: 0,
  posBusinessCost: 15,
  posBusinessPrice: 0,
  posIndependent: 0,
  posIndependentCost: 0,
  posIndependentPrice: 0,
  promotions: 0,
  promotionsCost: 20,
  promotionsPrice: 0,
  selfServiceKiosk: 0,
  selfServiceKioskCost: 30,
  selfServiceKioskPrice: 0,
  totalRestaurantManagement: 0,
  totalMarketing: 0,
};

class SubscriptionsStore {
  @observable subscriptions = initSubscriptions;
  @observable restaurantManagementArray = subscriptionsRestaurantManagementArray;
  @observable marketingArray = subscriptionsMarketingArray;
  @observable loading = false;

  @action
  changeSubscriptions = (key, value) => {
    let numberValue = Number(value);
    if (key === 'promotions' || key === 'giftCards') {
      if (numberValue > 1) {
        numberValue = 1;
      } else if (numberValue < 0) {
        numberValue = 0;
      }
    }
    runInAction(() => {
      let newSubscriptions = {
        ...this.subscriptions,
        [key]: numberValue,
        [`${key}Price`]: numberValue * this.subscriptions[`${key}Cost`] || 0,
      };
      this.subscriptions = {
        ...newSubscriptions,
        ...getTotalRestaurantManagementAndMarketing(newSubscriptions),
      };
    });
  };

  @action
  getSubscriptions = async merchantsGroupId => {
    this.loading = true;
    try {
      const data = await SubscriptionsAPI.getSubscriptions(merchantsGroupId);
      runInAction(() => {
        this.subscriptions = { ...data, ...getTotalRestaurantManagementAndMarketing(data) };
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getStructuredData = subscriptions => {
    const data = [subscriptionsRestaurantManagementArray, subscriptionsMarketingArray].map(
      array => {
        return array.reduce(
          (acc, item) => ({ ...acc, [item]: Number(subscriptions[item] || 0) }),
          {},
        );
      },
    );
    return { ...data[0], ...data[1] };
  };

  @action
  saveSubscriptions = async merchantGroupId => {
    this.loading = true;
    const { id } = this.subscriptions;
    const newSubscriptions = { id, merchantGroupId, ...this.getStructuredData(this.subscriptions) };
    try {
      const data = await SubscriptionsAPI.saveSubscriptions(newSubscriptions);
      runInAction(() => {
        this.subscriptions = { ...data, ...getTotalRestaurantManagementAndMarketing(data) };
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  removeStore = () => {
    runInAction(() => {
      this.subscriptions = initSubscriptions;
      this.loading = false;
    });
  };
}

export default SubscriptionsStore;
