import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 10px 10px;

  .message {
    position: relative;
    width: 50%;
    max-width: 300px;

    & > .close.icon {
      top: 49%;
      transform: translateY(-50%);
    }
  }
`;
