import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';

import MerchantAPI from 'api/merchant';

const INIT_DATA = {
  checklistItems: [],
  launchTimestamp: '',
  questions: [],
};

class LaunchStore {
  @observable data = { ...INIT_DATA };
  @observable loading = false;

  @computed get launchDate() {
    return moment(this.data.launchTimestamp)
      .format('DD.MM.YY')
      .toString();
  }

  @action
  getLaunchData = async merchantGroupId => {
    this.loading = true;
    try {
      const data = await MerchantAPI.getLaunchData(merchantGroupId);
      runInAction(() => {
        this.data = data;
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  changeChecklistItem = (index, checked) => {
    const newChecklistItems = [...this.data.checklistItems];
    const changedItem = newChecklistItems[index];
    newChecklistItems[index] = {
      ...changedItem,
      result: checked,
      timestamp: moment().valueOf(),
    };

    runInAction(() => {
      this.data = {
        ...this.data,
        checklistItems: newChecklistItems,
      };
    });
  };

  @action
  saveData = async merchantGroupId => {
    const obj = {
      ...this.data,
    };

    try {
      await MerchantAPI.putLaunchData(merchantGroupId, obj);
    } catch (e) {
      throw new Error(e.message);
    }
  };
}

export default LaunchStore;
