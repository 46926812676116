import { extractFiltersQuery } from 'api/utils/helpers';
import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/distributor/invoice';

class AnalyticsAPI {
  static getList = (obj) => {
    return Request.GET(`${ENDPOINT}/filtered?${extractFiltersQuery(obj)}`);
  };
}

export default AnalyticsAPI;
