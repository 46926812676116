import { action, observable, runInAction } from 'mobx';
import MasterAPI from 'api/master';

class MasterStore {
  @observable masterSales = { items: [] };
  @observable masterSalesLoading = false;

  @observable masterRestaurants = { items: [] };
  @observable masterRestaurantsLoading = false;

  @action
  fetchMasterRestaurants = async (page, size) => {
    this.masterRestaurantsLoading = true;
    const data = await MasterAPI.getMasterList({ page, size });

    runInAction(() => {
      this.masterRestaurants = {
        ...data,
        items: data.merchant || [],
      };
      this.masterRestaurantsLoading = false;
    });
  }

  @action
  fetchMasterSales = async (page, size, params) => {
    this.masterSalesLoading = true;
    const data = await MasterAPI.getMasterKPIList({ page, size, ...params });

    runInAction(() => {
      this.masterSales = data;
      this.masterSalesLoading = false;
    });
  };
}

export default MasterStore;
