import ModalStore from './Modals';
import MobileMenuStore from './MobileMenu';
import NotificationStore from './Notification';

const Modal = new ModalStore();
const MobileMenu = new MobileMenuStore();
const Notification = new NotificationStore();

export default {
  Modal,
  Notification,
  MobileMenu
};
